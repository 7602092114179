@media only screen and (max-width: 1120px) {
  .vc {
    width: 100% !important;
  }
}

.r-w-small {
  width: 80% !important;
}
.float-right {
  margin-left: auto;
}
