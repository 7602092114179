.navMenu {
  /* background-color: red !important; */
  /* height: 100vh; */
  display: flex;
}
.navMobile {
  display: none;
  /* display: flex; */
  flex-direction: column;
  align-items: center;
  padding-left: 15px;
  justify-content: space-around;
  margin-right: 1%;
  background-color: white;
  width: 5rem;
  height: 90vh;
}
.t {
  /* width: 6rem; */
  margin-right: 3%;
}
.w {
  width: 80%;
}
.w1 {
  width: 200%;
}

@media only screen and (max-width: 991px) {
  .navMobile {
    display: flex;
    position: sticky;
  }
}
.float-left {
  float: left;
}
