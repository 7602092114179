.navMenu {
    /* background-color: red !important; */
    height: 100vh;
    display: flex;
    /* margin-left: 1%; */
}
.navMobile {
    display: none;
    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin-right: 1%;
    background-color: white;
    width: 5rem;
    height: 80vh;
}
.t {
    /* width: 6rem; */
    margin-right: 3%;
}

.gt1 {
    justify-content: space-between;
}

input[type='checkbox'] {
    accent-color: red;
}

@media only screen and (max-width : 991px)  {
    .navMobile {
        display: flex;
        position: sticky;
    }
}