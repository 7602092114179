/* Phones */
@media (max-width: 570px) {
  .adj-left {
    margin-left: auto;
    display: flex;
  }
  .mobile_btn {
    display: block !important;
    width: 100%;
  }
  .desk-nav {
    display: none !important;
  }
}

/* styles for tablets */
@media (min-width: 768px) and (max-width: 1024px) {
  .adj-left {
    margin-left: auto;
  }
  .mobile_btn {
    display: none;
  }
  .desk-nav {
    display: block;

    width: 100%;
  }
}

.adj-left {
  margin-left: auto;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}
.mobile_btn {
  display: none;
}
.desk-nav {
  display: block;
  width: 100%;
}
.text-sm {
  font: 20px;
  padding: 1px 5px 1px 5px;
}
