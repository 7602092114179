.exam {
  display: flex;
  /* flex-direction: column; */
  margin-left: 20%;
  justify-content: space-between;
  /* margin-top: 10%; */
  height: 40rem;
  width: 100%;
}

.radio__btn {
  display: flex;
  justify-content: space-between;
  height: 50%;
  margin-top: 2%;
  margin-left: 10%;
  /* align-items: center; */
  flex-direction: column;
}

.title {
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  font-size: xx-large;
}

.question {
  margin-bottom: 10%;
  display: flex;
  justify-content: center;
  font-size: large;
}

.edit {
  display: flex;
  justify-content: flex-end;
}
.edit1 {
  display: flex;
  justify-content: space-between;
}

.quiz {
  background-color: blue;
  width: 20rem;
}

.add_quiz {
  display: flex;
  width: 80%;
  background-color: aliceblue;
  justify-content: space-between;
}

.bulk-area-btn {
  margin-top: -20px;
  float: right;
}
.bulk-area {
  float: left;
}

/* Phones */
@media (max-width: 570px) {
  .bulk-area-btn {
    float: left;
    margin-left: -35px;
  }
  .bulk-area {
    float: left;
    margin-left: 10px;
  }
}
